@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/css/bootstrap.min.css');


body {
  margin: 0;
  font-family: "Mulish", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.homeOuter{
  min-height: 100vh;
}
.homeOuter .loader{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.homeOuter iframe{
  border: none;
}

#main{
  display: table;
  width: 100%;
  height: 100vh;
  text-align: center;
}

.fof{
  display: table-cell;
  vertical-align: middle;
}

.fof h1{
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: type .5s alternate infinite;
}

.homeSect{
  min-height: 100vh;
  background: #EAEAEA;
  width: 100%;
  position: relative;
}
.homeContent{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 600px;
}
.homeContent h1{
  font-size: 50px;
  padding-right: 12px;
  margin-bottom: 15px;
}
.homeContent h1 span{
  color: #1b49a0;
}

.form {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.form input {
  background: transparent;
  border: navajowhite;
  height: 40px;
  width: 100%;
  border-bottom: 1px solid #000;
  outline: none;
  padding-right: 50px;
  margin-bottom: 15px;
}

.form button {
  outline: none;
  cursor: pointer;
  background-color: blue;
  color: #fff;
  padding: 8px 20px;
  border: none;
  min-width: 100px;
  margin-top: 10px;
}
.form button span{
  margin-right: 10px;
}

.fileOuter {
  margin-top: 35px;
}

.fileOuter ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.fileOuter ul li {color: #1b49a0;font-size: 14px;line-height: 20px;margin-bottom: 10px;cursor: pointer;padding-bottom: 10px;border-bottom: 1px solid #ccc;}

@keyframes type{
  from{box-shadow: inset -3px 0px 0px #1b49a0;}
  to{box-shadow: inset -3px 0px 0px transparent;}
}

.loader-text {
  width: fit-content;
  font-weight: bold;
  font-family: monospace;
  font-size: 30px;
  background:linear-gradient(90deg,#1b49a0 50%,#0000 0) right/200% 100%;
  animation: l21 2s infinite linear;
}
.loader-text::before {
  content :"Loading Links...";
  color: #0000;
  padding: 0 5px;
  background: inherit;
  background-image: linear-gradient(90deg,#fff 50%,#1b49a0 0);
  -webkit-background-clip:text;
          background-clip:text;
}

@keyframes l21{
  100%{background-position: left}
}